import React from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import { AiOutlineTool, AiOutlineUser, AiOutlineGlobal, AiOutlineDollar } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import CEOsVision from '../components/CEOsVision';
import StayUpdated from '../components/StayUpdated';
import '../css/Home.css';
import '../css/IntroSection.css';

const Home = () => {
    return (
        <div>
            {/* SEO with Helmet */}
            <Helmet>
                <title>Cortex Dynamics - AI Solutions and Digital Transformation Consulting for Businesses</title>
                <meta
                    name="description"
                    content="Cortex Dynamics is a tech consulting firm in Mumbai offering AI-driven solutions, process automation, and business transformation. Explore our AI expertise today!"
                />
                <meta
                    name="keywords"
                    content="AI solutions, process automation, digital transformation, technology consulting, AI integration, Cortex Dynamics, AI for SMEs, healthcare AI solutions, AI for startups"
                />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.cortexdynamics.in" />
                
                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content="Cortex Dynamics - AI Solutions & Digital Transformation Consulting" />
                <meta property="og:description" content="Discover AI-driven solutions and digital transformation consulting tailored to your business needs with Cortex Dynamics." />
                <meta property="og:url" content="https://www.cortexdynamics.in" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.cortexdynamics.in/logo.jpg" />
                
                {/* Schema Markup for organization */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Cortex Dynamics",
                        "url": "https://www.cortexdynamics.in",
                        "logo": "https://www.cortexdynamics.in/logo.jpg",
                        "sameAs": [
                            "https://www.linkedin.com/company/cortexdynamics",
                            "https://twitter.com/cortexdynamics"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+91-XXXXXXXXXX",
                            "contactType": "Customer Support",
                            "areaServed": "IN"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Header />
            <HeroSection /> {/* HeroSection carousel */}

            {/* Intro Section */}
            <section className="intro-section">
                <div className="intro-content">
                    <h2>Welcome to Cortex Dynamics</h2>
                    <h4>AI-Powered Digital Transformation for Your Business</h4>
                    <h5>
                        At Cortex Dynamics, we empower businesses with advanced AI and digital solutions tailored to enhance efficiency, streamline operations, and achieve strategic objectives. Let us help you transform the way you work.
                    </h5>
                    <Link to="/contact" className="contact-button">Get in Touch</Link> {/* Contact Us button */}
                </div>
                <div className="key-points">
                    <div className="points-container">
                        <div className="point-card">
                            <AiOutlineTool size={50} />
                            <h4>Advanced AI Integration & Process Automation</h4>
                            <h5>Empower your operations with intelligent automation that adapts to your unique business needs.</h5>
                        </div>
                        <div className="point-card">
                            <AiOutlineUser size={50} />
                            <h4>Customized AI Solutions with a User-Centric Approach</h4>
                            <h5>We prioritize user experience, tailoring AI solutions that enhance customer engagement and operational ease.</h5>
                        </div>
                        <div className="point-card">
                            <AiOutlineGlobal size={50} />
                            <h4>Market Insights & a Team of Industry Experts</h4>
                            <h5>Benefit from deep local market insights combined with global expertise for maximum impact.</h5>
                        </div>
                        <div className="point-card">
                            <AiOutlineDollar size={50} />
                            <h4>Flexible and Innovative Pricing Models</h4>
                            <h5>Access cutting-edge solutions with pricing that adapts to your budget and business size.</h5>
                        </div>
                    </div>
                </div>
            </section>

            <Services />
            <CEOsVision />
            <Testimonials />
            <StayUpdated />
            <Footer />
        </div>
    );
};

export default Home;
