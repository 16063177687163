import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AssessmentPage from './pages/AssessmentPage';
import AssessmentForm from './components/AssessmentForm'; 
import ResourcesPage from './pages/ResourcesPage';
import PricingPage from './pages/PricingPage'; 
import AboutUsPage from './pages/AboutUsPage'; // Importing About Us Page
import ServicesPage from './pages/ServicesPage'; // Importing Services Page
import ContactUsPage from './pages/ContactUsPage'; // Corrected import path
import Careers from './pages/Careers';
import FAQ from './pages/FAQ';
import TermsofService from './pages/TermsofService'; // Import the Terms of Service page
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import Privacy Policy page
import AiStrategy from './services/AiStrategy'; // Import the AI Strategy page
import CustomSolutions from './services/CustomSolutions'; // Import the AI Strategy page
import DataAnalytics from './services/DataAnalytics'; // Import the AI Strategy page
import AutomationSolutions from './services/AutomationSolutions'; // Import the Automation Solutions page
import DigitalTransformation from './services/DigitalTransformation'; // Import the Digital Transformation page
import UXUIDesign from './services/UXUIDesign'; // Import the UX/UI Design page
import { HelmetProvider } from 'react-helmet-async';



import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <HelmetProvider>
    <Router> 
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/assessmentpage" element={<AssessmentPage />} />
        <Route path="/assessment-form" element={<AssessmentForm />} />    
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/pricing" element={<PricingPage />} /> {/* Pricing Page */}
        <Route path="/aboutus" element={<AboutUsPage />} /> {/* Added About Us Page route */}
        <Route path="/services" element={<ServicesPage />} /> {/* Added Services Page route */}
        <Route path="/contact" element={<ContactUsPage />} /> {/* Added Contact Us Page route */}
        <Route path="/faq" element={<FAQ />} /> {/* Added FAQ Page route */}
        <Route path="/careers" element={<Careers />} /> {/* Added Careers Page route */}  
        <Route path="/terms-of-service" element={<TermsofService />} /> {/* Added Terms of Service route */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Added Privacy Policy route */}
        <Route path="/ai-strategy" element={<AiStrategy />} /> {/* Added AI Strategy Page route */}
        <Route path="/custom-solutions" element={<CustomSolutions />} /> {/* Added Custom Solutions Page route */}
        <Route path="/data-analytics" element={<DataAnalytics />} /> {/* Added Data Analytics Page route */}
        <Route path="/automation-solutions" element={<AutomationSolutions />} /> {/* Added Automation Solutions Page route */}
        <Route path="/digital-transformation" element={<DigitalTransformation />} /> {/* Added Digital Transformation Page route */}
        <Route path="/ux-ui-design" element={<UXUIDesign />} /> {/* Added UX/UI Design Page route */}
      </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;
