import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import '../css/Services.css'; // Import CSS for Services section

const Services = () => {
    return (
        <div className="services-section">
            <h2>Our Expertise</h2>
            <div className="services-grid">
                <div className="service-card">
                        <h3>AI Strategy Consulting</h3>
                        <p>We help businesses define and implement an AI strategy aligned with their goals to achieve maximum value.</p>
                    <Link className="learn-more-link" to="/ai-strategy">Learn More</Link>
                </div>
                <div className="service-card">
                        <h3>Custom and Tailored Solutions</h3>
                        <p>We offer tailored AI solutions designed specifically for your business to meet unique challenges.</p>
                    <Link className="learn-more-link" to="/custom-solutions">Learn More</Link>
                </div>
                <div className="service-card">
                        <h3>Data Analytics</h3>
                        <p>Turn your data into actionable insights and optimize decision-making with our advanced data analytics services.</p>
                    <Link className="learn-more-link" to="/data-analytics">Learn More</Link>
                </div>
                <div className="service-card">
                        <h3>Automation Solutions</h3>
                        <p>Streamline processes and reduce manual efforts through intelligent automation solutions.</p>
                    <Link className="learn-more-link" to="/automation-solutions">Learn More</Link>
                </div>
                <div className="service-card">
                        <h3>Digital Transformation</h3>
                        <p>Drive innovation and transform your business with the latest digital technologies and AI solutions.</p>
                    <Link className="learn-more-link" to="/digital-transformation">Learn More</Link>
                </div>
                <div className="service-card"> 
                        <h3>UX/UI Design</h3>
                        <p>Create engaging, user-friendly digital experiences with our expert UX/UI design services.</p>
                    <Link className="learn-more-link" to="/ux-ui-design">Learn More</Link>
                </div>
            </div>
        </div>
    );
};

export default Services;
