import React from 'react';
import Header from '../components/Header'; // Import Header component
import Footer from '../components/Footer'; // Import Footer component
import '../css/Careers.css'; // Include CSS for the Careers page
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO


const Careers = () => {
    return (
        <div className="careers-page">
            <Helmet>
                <title>Careers at Cortex Dynamics - Join Our Team</title>
                <meta
                    name="description"
                    content="Explore exciting career opportunities at Cortex Dynamics. Join our team of experts in AI-driven solutions and make a difference."
                />
                <meta
                    name="keywords"
                    content="careers, job openings, Cortex Dynamics, AI solutions, technology jobs, marketing jobs, design jobs"
                />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.cortexdynamics.in/careers" />
            </Helmet>
            <Header />
            <div className="careers-content">
                <h2>Join Our Team at CortexDynamics</h2>
                <p>We are on a mission to transform businesses with AI-driven solutions. If you are passionate about technology, design, or marketing, we invite you to explore our career opportunities below.</p>

                <div className="team-division">
                    <h3>Technical Team</h3>
                    <div className="job-listing">
                        <h4>CTO</h4>
                        <p>Lead our technology strategy and oversee the development of AI solutions.</p>
                    </div>
                    <div className="job-listing">
                        <h4>AI/ML Engineer</h4>
                        <p>Design and implement machine learning models to drive business insights.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Full-Stack Developer</h4>
                        <p>Develop and maintain web applications, ensuring a seamless user experience.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Cybersecurity Specialist</h4>
                        <p>Protect our systems and data from security threats and vulnerabilities.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Data Science Intern </h4>
                        <p>Assist in data analysis and model development to support various projects.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Software Development Intern </h4>
                        <p>Work closely with developers to gain hands-on experience in software development.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Cybersecurity Intern </h4>
                        <p>Learn about cybersecurity practices while assisting in system protection.</p>
                    </div>
                </div>

                <div className="team-division">
                    <h3>Design Team</h3>
                    <div className="job-listing">
                        <h4>CMO</h4>
                        <p>Oversee our marketing strategies and lead the design team in creative projects.</p>
                    </div>
                    <div className="job-listing">
                        <h4>UX/UI Designer</h4>
                        <p>Create user-friendly designs that enhance the overall user experience.</p>
                    </div>
                    <div className="job-listing">
                        <h4>UI/UX Intern </h4>
                        <p>Assist in design tasks and gain valuable experience in user interface design.</p>
                    </div>
                </div>

                <div className="team-division">
                    <h3>Marketing Team</h3>
                    <div className="job-listing">
                        <h4>Marketing Specialist</h4>
                        <p>Develop and execute marketing campaigns to promote our services.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Business Development Manager</h4>
                        <p>Identify new business opportunities and build relationships with potential clients.</p>
                    </div>
                    <div className="job-listing">
                        <h4>Marketing Intern </h4>
                        <p>Support the marketing team with research, campaign execution, and content creation.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Careers;
