// frontend/src/pages/ResourcesPage.js

import React, { useState, useEffect } from 'react';
import '../css/ResourcesPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO


// ResourceItem Component
const ResourceItem = ({ icon, title, description, link }) => {
    return (
        <div className="resource-item">
            <h3>
                <i className={icon}></i> {title}
            </h3>
            <p>{description}</p>
            <a href={link} className="resource-link">Download</a>
        </div>
    );
};

// ResourcesPage Component
const ResourcesPage = () => {
    const [loading, setLoading] = useState(true);

    // Simulated data fetching (replace with actual API call if needed)
    useEffect(() => {
        const fetchData = async () => {
            // Simulate an API call
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
        };

        fetchData();
    }, []);

    // Resource Data
    const guides = [
        {
            icon: "fas fa-book",
            title: "AI for Business: A Complete Guide",
            description: "Learn the fundamentals of implementing AI solutions in business environments.",
            link: "/guides/ai-for-business"
        },
        {
            icon: "fas fa-robot",
            title: "Machine Learning 101",
            description: "Explore the basics of machine learning and how it’s changing industries worldwide.",
            link: "/guides/ml-101"
        }
    ];

    const caseStudies = [
        {
            icon: "fas fa-industry",
            title: "AI in Manufacturing",
            description: "How AI-powered automation increased efficiency and reduced costs.",
            link: "/case-studies/manufacturing"
        },
        {
            icon: "fas fa-stethoscope",
            title: "AI in Healthcare",
            description: "Using machine learning to predict patient outcomes and optimize care.",
            link: "/case-studies/healthcare"
        }
    ];

    const whitepapers = [
        {
            icon: "fas fa-file-alt",
            title: "AI Trends 2024",
            description: "A comprehensive report on the latest trends and predictions for AI development.",
            link: "/whitepapers/ai-trends-2024"
        },
        {
            icon: "fas fa-lightbulb",
            title: "AI Innovation in Retail",
            description: "How leading retail brands are leveraging AI for better customer experiences.",
            link: "/whitepapers/ai-in-retail"
        }
    ];

    const toolkits = [
        // {
        //     icon: "fas fa-tools",
        //     title: "AI Implementation Toolkit",
        //     description: "A complete toolkit for businesses looking to integrate AI into their operations.",
        //     link: "/toolkits/ai-implementation"
        // },
        {
            icon: "fas fa-cogs",
            title: "AI Governance Framework",
            description: "A step-by-step framework to ensure your AI projects comply with regulations and best practices.",
            link: "toolkits/ai-governance.pdf"
        }
    ];

    return (
        <div className="resources-page">
             <Helmet>
                <title>AI Resources - Cortex Dynamics</title>
                <meta name="description" content="Explore our AI resources, including guides, case studies, whitepapers, and toolkits to aid your digital transformation." />
            </Helmet>
            <Header />

            <main>
                {/* Hero Section */}
                <section className="resources-hero">
                    <h1>Explore Our AI Resources</h1>
                    <h3>
                        Your go-to hub for AI insights, case studies, whitepapers, toolkits, and much more to guide your digital transformation.
                    </h3>
                </section>

                {loading ? (
                    <div className="loading">Loading resources...</div>
                ) : (
                    <>
                        {/* Guides Section */}
                        {/* <section className="resource-section">
                            <h2>Guides</h2>
                            <p>Download our comprehensive AI and data analytics guides to kickstart your journey.</p>
                            <div className="resource-list">
                                {guides.map((guide, index) => (
                                    <ResourceItem key={index} {...guide} />
                                ))}
                            </div>
                        </section> */}

                        {/* Case Studies Section */}
                        {/* <section className="resource-section">
                            <h2>Case Studies</h2>
                            <p>Real-world examples of AI in action across various industries.</p>
                            <div className="resource-list">
                                {caseStudies.map((study, index) => (
                                    <ResourceItem key={index} {...study} />
                                ))}
                            </div>
                        </section> */}

                        {/* Whitepapers Section */}
                        {/* <section className="resource-section">
                            <h2>Whitepapers</h2>
                            <p>Dive deep into industry reports and research papers on AI trends and innovations.</p>
                            <div className="resource-list">
                                {whitepapers.map((paper, index) => (
                                    <ResourceItem key={index} {...paper} />
                                ))}
                            </div>
                        </section> */}

                        {/* Toolkits Section */}
                        <section className="resource-section">
                            <h2>Toolkits</h2>
                            <p>Ready-to-use templates and frameworks for implementing AI solutions.</p>
                            <div className="resource-list">
                                {toolkits.map((toolkit, index) => (
                                    <ResourceItem key={index} {...toolkit} />
                                ))}
                            </div>
                        </section>
                    </>
                )}
            </main>

            <Footer />
        </div>
    );
};

export default ResourcesPage;
