import React, { useState } from 'react';
import '../css/StayUpdated.css'; // Import your CSS file for this component

const StayUpdated = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your subscription logic here, such as sending data to an API
        console.log(`Name: ${name}, Email: ${email}`);
        // Clear input fields after submission
        setName('');
        setEmail('');
    };

    return (
        <div className="stay-updated-section">
            <h2>Stay Updated</h2>
            <p>Subscribe to our newsletter for the latest updates and insights.</p>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Your Name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                />
                <input 
                    type="email" 
                    placeholder="Your Email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
                <button type="submit">Keep Me Updated</button>
            </form>
        </div>
    );
};

export default StayUpdated;
