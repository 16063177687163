import React, { useState } from 'react';
import '../css/ContactUsPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.success) {
        setResponseMessage('Your message has been sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '' }); // Reset form
      } else {
        setResponseMessage('Error sending message. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Error sending message. Please try again.');
    }
  };

  return (
    <div className="contact-us-page">
      <Helmet>
        <title>Contact Us - Cortex Dynamics</title>
        <meta name="description" content="Get in touch with Cortex Dynamics." />
        <meta name="keywords" content="contact us, Cortex Dynamics, AI solutions" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.cortexdynamics.in/contact-us" />
      </Helmet>
      <Header />
      <div className="content">
        <h1>Contact Us</h1>
        <p className="intro-text">
          We’d love to hear from you! Whether you have questions, feedback, or would like to explore our services,
          feel free to reach out to us using the form below or directly at our email.
        </p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button type="submit" className="submit-button">Send Message</button>
        </form>

        {responseMessage && <p className="response-message">{responseMessage}</p>}

        <div className="contact-info">
          <h2>Contact Information</h2>
          <p><strong>Email:</strong> kavinparikh@cortexdynamics.in</p>
          <p><strong>Phone:</strong> +91 9653218346</p>
          <p><strong>Address:</strong> Mumbai, India</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
