import React from 'react';
import Header from '../components/Header'; // Assuming the header component is in this path
import Footer from '../components/Footer'; // Assuming the footer component is in this path
import '../css/PrivacyPolicy.css'; // Import your CSS file for styles if needed

const PrivacyPolicy = () => {
    return (
        <>
        <Header /> {/* Include the Header component */}
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>Effective date: October, 2024</p>
            
            <h2>1. Introduction</h2>
            <p>Welcome to CortexDynamics! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share information about you when you use our website.</p>
            
            <h2>2. Information We Collect</h2>
            <p>We may collect various types of information, including:</p>
            <ul>
                <li>Personal Information: This includes your name, email address, phone number, and any other information you provide when you register or interact with our services.</li>
                <li>Usage Data: We may collect information about how you access and use our website, including your IP address, browser type, pages visited, and the time and date of your visits.</li>
                <li>Cookies: We use cookies and similar tracking technologies to enhance your experience on our website. You can choose to accept or decline cookies through your browser settings.</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>We may use your information for various purposes, including:</p>
            <ul>
                <li>To provide and maintain our services.</li>
                <li>To notify you about changes to our services.</li>
                <li>To allow you to participate in interactive features when you choose to do so.</li>
                <li>To provide customer support.</li>
                <li>To gather analysis or valuable information so that we can improve our services.</li>
                <li>To monitor the usage of our website.</li>
                <li>To detect, prevent, and address technical issues.</li>
            </ul>

            <h2>4. Sharing Your Information</h2>
            <p>We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:</p>
            <ul>
                <li>With service providers to perform services on our behalf.</li>
                <li>To comply with legal obligations or respond to legal requests.</li>
                <li>To protect and defend our rights and property.</li>
                <li>With your consent.</li>
            </ul>

            <h2>5. Security of Your Information</h2>
            <p>We take reasonable steps to protect your personal information from loss, theft, and misuse, as well as unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

            <h2>6. Your Rights</h2>
            <p>You have the right to access, update, or delete the personal information we hold about you. If you wish to exercise these rights, please contact us.</p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <ul>
                <li>Email: <a href="mailto:info@cortexdynamics.com">kavinparikh20@gmail.com</a></li>
                <li>Phone: <a href="tel:+1234567890">+91 96532 18346</a></li>
            </ul>
        </div>
        <Footer /> {/* Include the Footer component */}
    </>
    );
};

export default PrivacyPolicy;
