// src/services/AutomationSolutions.js
import React from 'react';
import Header from '../components/Header'; // Adjust the import path as needed
import Footer from '../components/Footer'; // Adjust the import path as needed
import '../css/AutomationSolutions.css'; // Import the corresponding CSS file

const AutomationSolutions = () => {
    return (
        <>
            <Header />
            <div className="automation-solutions-container">
                <h1>Automation Solutions for Your Business</h1>
                <p>
                    In an increasingly competitive landscape, automation is crucial for streamlining 
                    operations and enhancing productivity. Our automation solutions are designed to 
                    help your business optimize processes, reduce errors, and increase efficiency.
                </p>

                <h2>Key Benefits of Our Automation Solutions</h2>
                <ul>
                    <li>
                        <strong>Increased Efficiency:</strong> Automate repetitive tasks to free up valuable 
                        time for your team.
                    </li>
                    <li>
                        <strong>Cost Savings:</strong> Reduce operational costs by minimizing manual labor 
                        and errors.
                    </li>
                    <li>
                        <strong>Improved Accuracy:</strong> Automation reduces the likelihood of human error, 
                        leading to more reliable results.
                    </li>
                    <li>
                        <strong>Scalability:</strong> Easily scale your operations as your business grows 
                        without the need for extensive manual input.
                    </li>
                </ul>

                <h2>Our Approach to Automation</h2>
                <p>
                    We follow a structured approach to implement automation solutions tailored to your 
                    business needs:
                </p>
                <ol>
                    <li>
                        <strong>Assessment:</strong> Analyze current processes to identify areas for 
                        automation.
                    </li>
                    <li>
                        <strong>Design:</strong> Create a customized automation strategy that aligns with 
                        your business objectives.
                    </li>
                    <li>
                        <strong>Implementation:</strong> Deploy automation tools and technologies to 
                        streamline operations.
                    </li>
                    <li>
                        <strong>Monitoring:</strong> Continuously monitor the performance of automated 
                        processes and make necessary adjustments.
                    </li>
                </ol>

                <h2>Why Choose Us?</h2>
                <p>
                    Our team of experts has extensive experience in delivering effective automation 
                    solutions across various industries. We are committed to helping you achieve 
                    operational excellence through tailored automation strategies.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default AutomationSolutions;
