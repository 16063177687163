import React from 'react';
import '../css/AboutUsPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <Helmet>
        <title>About Us - Cortex Dynamics | AI Consulting Firm</title>
        <meta
          name="description"
          content="Cortex Dynamics is a forward-thinking AI consulting firm dedicated to helping businesses harness the power of AI through bespoke solutions and innovative strategies."
        />
        <meta
          name="keywords"
          content="About Cortex Dynamics, AI consulting, artificial intelligence, business solutions, technology consulting, AI strategies"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.cortexdynamics.in/about-us" />
      </Helmet>
      <Header />
      <div className="content">
        <h1>About Us</h1>
        <p className="intro-text">
          CortexDynamics is a forward-thinking AI consulting firm, dedicated to helping businesses
          harness the transformative power of artificial intelligence. With a commitment to innovation,
          user-centric design, and bespoke solutions, we deliver measurable value to our clients. Our
          team of experts leverages cutting-edge AI technologies to provide customized solutions that
          drive efficiency, improve decision-making, and optimize business processes.
        </p>

        <section className="our-mission">
          <h2>Our Mission</h2>
          <p>
            Our mission is to empower businesses by integrating advanced AI technologies that enhance
            their capabilities, optimize their operations, and foster long-term success. We strive to
            create tailored AI strategies that align with each client’s unique challenges and business goals,
            helping them navigate the digital transformation journey with confidence.
          </p>
        </section>

        <section className="vision-for-the-future">
          <h2>Our Vision for the Future</h2>
          <p>
            At CortexDynamics, we envision a future where AI not only augments business processes but also drives sustainable growth, innovation, and inclusivity. Our goal is to make AI accessible, scalable, and beneficial to businesses of all sizes, enabling them to unlock their full potential in an increasingly competitive world.
          </p>
          <p>
            We believe that by fostering a culture of innovation, transparency, and collaboration, we can help shape a future where AI empowers businesses to thrive, innovate, and make smarter decisions—faster.
          </p>
        </section>

        <section className="competitive-advantages">
          <h2>Why Us?</h2>
          <p>At CortexDynamics, we believe that our strengths set us apart in a competitive market. Here’s what makes us unique:</p>
          <div className="advantages-list">
            <div className="advantage">
              <h3>Advanced AI Integration</h3>
              <p>
                Our firm leverages state-of-the-art AI technologies to deliver innovative solutions, setting us apart
                from traditional consulting firms. By incorporating machine learning, natural language processing,
                and predictive analytics, we offer cutting-edge insights and automation.
              </p>
            </div>
            <div className="advantage">
              <h3>User-Centric Approach</h3>
              <p>
                We prioritize user feedback and tailor our AI solutions based on real-world user interactions. This
                ensures our solutions are technologically advanced and highly relevant to the specific challenges our clients face.
              </p>
            </div>
            <div className="advantage">
              <h3>Customized AI Solutions</h3>
              <p>
                Unlike generic consulting services, we provide bespoke AI strategies tailored to each client's specific
                challenges. This customization enhances the effectiveness of our solutions and drives greater business value.
              </p>
            </div>
            <div className="advantage">
              <h3>Process Automation Expertise</h3>
              <p>
                Our expertise in AI-driven process automation helps businesses streamline operations, reduce costs,
                and improve efficiency. This practical expertise in automation sets us apart in the competitive market.
              </p>
            </div>
            <div className="advantage">
              <h3>Comprehensive Service Offering</h3>
              <p>
                We offer a broad range of services, including AI strategy consulting, data analytics, process automation,
                digital transformation, and UX/UI design. Our holistic approach allows us to address multiple aspects
                of business technology needs.
              </p>
            </div>
            <div className="advantage">
              <h3>Local Market Insight</h3>
              <p>
                Based in Mumbai, we have a deep understanding of the local business landscape and its unique challenges.
                This regional expertise allows us to offer solutions specifically designed for the dynamic Mumbai market.
              </p>
            </div>
            <div className="advantage">
              <h3>Scalable Solutions</h3>
              <p>
                Our AI solutions are designed to be scalable, ensuring that as businesses grow, our technology can evolve with them.
                This scalability supports long-term partnerships and sustained value creation.
              </p>
            </div>
            <div className="advantage">
              <h3>Innovative Pricing Models</h3>
              <p>
                We employ AI-enhanced pricing strategies that allow us to offer competitive and flexible pricing models.
                This innovation in pricing helps us attract and retain clients while maximizing profitability.
              </p>
            </div>
            <div className="advantage">
              <h3>Dedicated Team of Experts</h3>
              <p>
                Our team consists of experienced professionals with strong backgrounds in AI and technology. The diverse
                expertise within our team ensures that we are well-equipped to tackle complex challenges and deliver high-quality solutions.
              </p>
            </div>
            <div className="advantage">
              <h3>Future-Forward Vision</h3>
              <p>
                We are committed to staying at the forefront of AI technology trends and advancements. Our proactive
                approach to adopting and integrating emerging technologies ensures that we provide the most current and
                effective solutions available.
              </p>
            </div>
            <div className="advantage">
              <h3>AI Ethics and Governance</h3>
              <p>
                Our firm is dedicated to ethical AI practices, focusing on transparency, fairness, and accountability.
                We help clients navigate the complex landscape of AI governance and ensure ethical AI usage across their businesses.
              </p>
            </div>
            <div className="advantage">
              <h3>Explainable AI (XAI)</h3>
              <p>
                We specialize in explainable AI, helping clients understand and trust AI decisions. This is especially
                valuable in industries like finance and healthcare, where transparency is critical to success.
              </p>
            </div>
            <div className="advantage">
              <h3>AI-Driven Innovation</h3>
              <p>
                We focus on developing innovative AI applications and use cases that go beyond traditional consulting services,
                including the development of proprietary AI tools and exploration of emerging technologies like generative AI.
              </p>
            </div>
            <div className="advantage">
              <h3>Data Privacy and Compliance</h3>
              <p>
                We help clients navigate complex data privacy regulations and ensure compliance with AI ethics standards.
                Our expertise in this area ensures that your business remains compliant while leveraging the full potential of AI.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
