// src/pages/services/UXUIDesign.js
import React from 'react';
import Header from '../components/Header'; // Adjust the import path as needed
import Footer from '../components/Footer'; // Adjust the import path as needed
import { Link } from 'react-router-dom'; // Import Link for navigation

import '../css/UXUIDesign.css'; // Import the corresponding CSS file

const UXUIDesign = () => {
    return (
        <>
            <Header />
            <div className="uxui-design-container">
                <h1>UX/UI Design Services</h1>
                <p>
                    Our UX/UI Design services focus on creating engaging, user-centered interfaces that 
                    enhance the overall user experience. We believe that great design is essential for 
                    business success in the digital age.
                </p>

                <h2>Why Invest in UX/UI Design?</h2>
                <p>
                    Investing in quality UX/UI design can lead to significant benefits for your business:
                </p>
                <ul>
                    <li>
                        <strong>Improved User Satisfaction:</strong> A well-designed interface meets user needs 
                        and expectations, leading to higher satisfaction and loyalty.
                    </li>
                    <li>
                        <strong>Increased Conversion Rates:</strong> Effective UX/UI design can guide users 
                        towards desired actions, boosting conversion rates.
                    </li>
                    <li>
                        <strong>Reduced Development Costs:</strong> A solid design process helps identify 
                        potential issues early, reducing costly changes later in development.
                    </li>
                    <li>
                        <strong>Brand Consistency:</strong> Consistent design across all platforms reinforces 
                        your brand identity and strengthens customer recognition.
                    </li>
                </ul>

                <h2>Our Approach to UX/UI Design</h2>
                <p>
                    We follow a structured approach to ensure your design needs are met:
                </p>
                <ol>
                    <li>
                        <strong>User Research:</strong> We conduct thorough research to understand your target 
                        audience and their needs.
                    </li>
                    <li>
                        <strong>Wireframing and Prototyping:</strong> We create wireframes and prototypes to 
                        visualize the design and gather feedback.
                    </li>
                    <li>
                        <strong>Visual Design:</strong> Our design team develops an aesthetically pleasing 
                        and functional interface.
                    </li>
                    <li>
                        <strong>User Testing:</strong> We perform user testing to validate design decisions and 
                        ensure usability.
                    </li>
                </ol>

                <h2>Let’s Create Impactful Designs Together</h2>
                <p>
                    Our dedicated team is ready to collaborate with you to develop UX/UI designs that elevate 
                    your business and engage your users. Please <Link to="/contact">contact us</Link> to learn more about our design services!
                </p>
            </div>
            <Footer />
        </>
    );
};

export default UXUIDesign;
