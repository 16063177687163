import React from 'react';
import Header from '../components/Header'; // Make sure to import your Header component
import Footer from '../components/Footer'; // Make sure to import your Footer component
import '../css/FAQ.css'; // Include the CSS for the FAQ page
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO


const FAQ = () => {
    return (
        <div className="faq-page">
            <Helmet>
                <title>Frequently Asked Questions - Cortex Dynamics</title>
                <meta name="description" content="Find answers to common questions about Cortex Dynamics and our AI consulting services." />
                <meta name="keywords" content="AI, consulting, FAQ, Cortex Dynamics, artificial intelligence, business solutions" />
                <link rel="canonical" href="https://www.cortexdynamics.in/faq" />
            </Helmet>
            <Header />
            <div className="faq-content">
                <h2>Frequently Asked Questions</h2>
                <p>Welcome to our FAQ section! Here, we answer some of the most common questions about CortexDynamics and our AI consulting services. If you have any other inquiries, feel free to reach out to us.</p>
                
                <div className="faq-item">
                    <h4>What is CortexDynamics?</h4>
                    <p>CortexDynamics is an AI-driven consulting firm that helps businesses implement tailored AI solutions to enhance their operations and achieve their strategic goals.</p>
                </div>
                
                <div className="faq-item">
                    <h4>How does your AI consulting process work?</h4>
                    <p>Our process begins with an AI maturity assessment, followed by tailored recommendations and the implementation of suitable AI tools to achieve your business objectives effectively.</p>
                </div>
                
                <div className="faq-item">
                    <h4>What industries do you serve?</h4>
                    <p>We serve a variety of industries, including finance, healthcare, retail, and manufacturing, helping them leverage AI to optimize their operations and improve decision-making.</p>
                </div>
                
                <div className="faq-item">
                    <h4>Can you customize solutions for my business?</h4>
                    <p>Yes, we offer customized AI solutions that cater to the specific needs and challenges of your business, ensuring a perfect fit for your operational requirements.</p>
                </div>
                
                <div className="faq-item">
                    <h4>How can I contact your support team?</h4>
                    <p>You can reach our support team through the "Contact Us" section on our website, or email us directly at <a href="mailto:kavinparikh20@gmail.com">kavinparikh20@gmail.com</a>.</p>
                </div>
                
                <div className="faq-item">
                    <h4>What are the benefits of using AI in my business?</h4>
                    <p>AI can enhance efficiency, reduce operational costs, improve decision-making, and drive innovation. Our team helps you unlock the potential of AI to transform your business.</p>
                </div>

                <div className="faq-item">
                    <h4>How do I get started with CortexDynamics?</h4>
                    <p>Getting started is simple! Contact us through the "Contact Us" page, and we'll set up an initial consultation to discuss your needs and how we can help.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default FAQ;
