import React from 'react';
import '../css/Footer.css'; // Optional: Import styles if needed
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa'; // Importing icons from React Icons

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section quick-links">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/aboutus">About Us</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </div>
                <div className="footer-section popular-links">
                    <h4>Popular Links</h4>
                    <ul>
                        {/* <li><a href="/careers">Careers</a></li> */}
                        {/* <li><a href="/case-studies">Case Studies</a></li> */}
                        <li><a href="/terms-of-service">Terms of Service</a></li> {/* Added Terms of Service link */}
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/faq">FAQs</a></li>
                    </ul>
                </div>
                <div className="footer-section get-in-touch">
                    <h4>Get in Touch</h4>
                    <ul>
                        <li>Email: <a href="mailto:info@cortexdynamics.com">kavinparikh@cortexdynamics.in</a></li>
                        <li>Phone: <a href="tel:+1234567890">+91 96532 18346</a></li>
                    </ul>
                </div>
                <div className="footer-section follow-us">
                    <h4>Follow Us</h4>
                    <div className="social-links">
                        {/* <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a> */}
                        <a href="https://x.com/CortexDynamics" target="_blank" rel="noopener noreferrer">
                            <FaTwitter />
                        </a>
                        <a href="https://www.linkedin.com/company/cortex-dynamics/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedinIn />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} CortexDynamics. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
