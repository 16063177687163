import React from 'react';
import Header from '../components/Header'; // Assuming the header component is in this path
import Footer from '../components/Footer'; // Assuming the footer component is in this path
import '../css/TermsofService.css'; // Import the CSS file

const TermsofService = () => {
  return (
    <>
      <Header /> {/* Include the Header component */}
      <div className="terms-container">
        <h1 className="terms-title">Terms of Service</h1>
        <p className="terms-last-updated">Last Updated: 16/10/2024</p>

        <section>
          <h2 className="terms-heading">1. Acceptance of Terms</h2>
          <p>
            By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and any future amendments. If you do not agree to these terms, please do not use our Services.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">2. Eligibility</h2>
          <p>
            You must be at least 18 years old to use our Services. By using our website, you represent and warrant that you meet this eligibility requirement.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">3. Account Registration</h2>
          <p>
            To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during registration and keep your account information updated. You are responsible for maintaining the confidentiality of your login credentials and are responsible for all activities that occur under your account.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">4. Use of Services</h2>
          <p>
            You agree to use our Services in compliance with all applicable laws and regulations. You may not:
          </p>
          <ul>
            <li>Use our Services for any unlawful purpose.</li>
            <li>Attempt to interfere with the proper functioning of our website or Services.</li>
            <li>Attempt to gain unauthorized access to our servers, systems, or databases.</li>
          </ul>
        </section>

        <section>
          <h2 className="terms-heading">5. Intellectual Property</h2>
          <p>
            All content, logos, trademarks, designs, and other intellectual property related to <strong>CortexDynamics</strong> are the exclusive property of <strong>CortexDynamics</strong> unless otherwise stated. You may not reproduce, distribute, or otherwise use our intellectual property without our express written consent.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">6. Pricing and Payments</h2>
          <p>
            For any paid Services, the price, payment terms, and additional terms will be specified at the point of purchase. All fees are non-refundable unless stated otherwise.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">7. Privacy Policy</h2>
          <p>
            Your use of our Services is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which explains how we collect, use, and protect your personal information.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">8. Third-Party Links</h2>
          <p>
            Our Services may contain links to third-party websites or services. These links are provided for convenience, and <strong>CortexDynamics</strong> is not responsible for the content or practices of those third-party websites.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">9. Termination</h2>
          <p>
            We reserve the right to suspend or terminate your account or access to our Services at any time, with or without cause, and with or without notice.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">10. Disclaimer of Warranties</h2>
          <p>
            Our Services are provided on an "as-is" and "as-available" basis. <strong>CortexDynamics</strong> makes no warranties, expressed or implied, regarding the operation or availability of our Services or the accuracy of information provided.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">11. Limitation of Liability</h2>
          <p>
            In no event shall <strong>CortexDynamics</strong> or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Services.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">12. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless <strong>CortexDynamics</strong> and its affiliates from any claims, damages, liabilities, or expenses arising from your use of our Services or violation of these Terms of Service.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">13. Changes to Terms</h2>
          <p>
            We reserve the right to modify or update these Terms of Service at any time. Any changes will be posted on this page, and your continued use of our Services constitutes acceptance of the updated terms.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">14. Governing Law</h2>
          <p>
            These Terms of Service shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
          </p>
        </section>

        <section>
          <h2 className="terms-heading">15. Contact Us</h2>
          <p>
            If you have any questions or concerns regarding these Terms of Service, please contact us at:
          </p>
          <p>
            <strong>CortexDynamics</strong><br />
            kavinparikh@cortexdynamics.in<br />
            +91 96532 18346<br />
            Mumbai, Maharashtra, India
          </p>
        </section>
      </div>
      <Footer /> {/* Include the Footer component */}
    </>
  );
};

export default TermsofService;
