import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import '../css/HeroSection.css'; // Import the styles for the carousel content


const HeroSection = () => {
    return (
        <div className="carousel-container">
            <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
                <div 
                    className="carousel-slide"
                    style={{
                        backgroundImage: `url('ai-insight.jpg')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '400px'
                    }}
                >
                    <div className="carousel-content">
                        <h3>AI-Driven Insights</h3>
                        <p>We provide businesses with actionable insights by leveraging AI to analyze complex data sets, helping you make informed decisions.</p>
                    </div>
                </div>
                
                <div 
                    className="carousel-slide"
                    style={{
                        backgroundImage: `url('user-centric.png')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '400px'
                    }}
                >
                    <div className="carousel-content">
                        <h3>User-Centric Solutions</h3>
                        <p>We develop and implement customized technology solutions based on your direct feedback, ensuring alignment with business goals.</p>
                    </div>
                </div>
                
                <div 
                    className="carousel-slide"
                    style={{
                        backgroundImage: `url('process-automation.png')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '400px'
                    }}
                >
                    <div className="carousel-content">
                        <h3>Process Automation</h3>
                        <p>We offer AI-powered automation solutions to optimize business processes, reduce costs, and increase operational efficiency.</p>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};

export default HeroSection;
