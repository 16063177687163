import React from 'react';
import '../css/Testimonials.css'; // Import CSS for the Testimonials component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

const Testimonials = () => {
    const testimonialsData = [
        {
            text: "The AI sentiment analysis tool was a game-changer for us. It provided clear insights into customer feedback, allowing us to address concerns effectively and improve our offerings. Since implementation, we’ve seen a significant increase in customer satisfaction and retention.",
            author: "DroolingTails Bakery",
        },
        {
            text: "CortexDynamics transformed how I manage my clinic. Their AI-powered system optimized appointment scheduling, reduced conflicts, and improved patient data security. Thanks to their solution, we've cut waiting times, boosted efficiency, and seen a 15% increase in patient retention. Highly recommend their expertise!",
            author: "Haawk Ayurvedic Clinic",
        },
    ];

    return (
        <section className="testimonials-section">
            <div className="testimonial-image">
            </div>
            <div className="testimonial-text">
                <h2>What Our Clients Say</h2>
                <Carousel 
                    autoPlay 
                    infiniteLoop 
                    showThumbs={false} 
                    showStatus={false}
                    interval={3000} // Change the interval to 3 seconds
                >
                    {testimonialsData.map((testimonial, index) => (
                        <div className="testimonial-card" key={index}>
                            <div className="testimonial-content">
                                <div className="testimonial-text-content">
                                    <p>"{testimonial.text}"</p>
                                    <h5>- {testimonial.author}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default Testimonials;
