import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // For routing
import '../css/Header.css';

const Header = () => {
    return (
        <AppBar position="static" style={{ backgroundColor: '#003366' }}>
            <Toolbar>
            <Link to="/">
                <img
                    src="/logo1.png" // Path to the image in the public directory
                    alt="CortexDynamics Logo"
                    className="logo-image" // Class for custom styling
                />
                </Link>
                <Button color="inherit" component={Link} to="/">Home</Button>
                {/* <Button color="inherit" component={Link} to="/assessmentpage">AI Assessment</Button> */}
                <Button color="inherit" component={Link} to="/aboutus">About Us</Button> {/* New About Us link */}
                <Button color="inherit" component={Link} to="/resources">Resources</Button>
                <Button color="inherit" component={Link} to="/pricing">Pricing</Button>
                <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
