import React from 'react';
import '../css/ServicesPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ServicesPage = () => {
  return (
    <div className="services-page">
      <Header />
      <div className="content">
        <h1>Our Services</h1>
        <p className="intro-text">
          At CortexDynamics, we offer a comprehensive suite of AI consulting services designed to help businesses leverage the power of artificial intelligence. Our solutions are tailored to meet the unique needs of each client, enabling them to optimize their operations, enhance decision-making, and drive sustainable growth. 
        </p>

        <section className="service-section">
          <h2>AI Strategy Consulting</h2>
          <p>
            Our AI Strategy Consulting services help organizations understand the potential of AI and create a roadmap for successful implementation. We assess your current capabilities, identify opportunities for improvement, and develop a customized AI strategy that aligns with your business objectives.
          </p>
        </section>

        <section className="service-section">
          <h2>Custom and Tailored Solutions</h2>
          <p>
            We understand that every business is unique, which is why we provide bespoke AI solutions tailored to your specific needs. Our team works closely with you to design and implement AI systems that seamlessly integrate with your existing processes and technology.
          </p>
        </section>

        <section className="service-section">
          <h2>Data Analytics</h2>
          <p>
            Unlock the potential of your data with our advanced data analytics services. We help you collect, analyze, and visualize data to derive actionable insights that can drive informed decision-making. Our analytics solutions cover a range of applications, from customer insights to operational efficiency.
          </p>
        </section>

        <section className="service-section">
          <h2>Process Automation</h2>
          <p>
            Our AI-driven process automation services help streamline your business operations, reducing manual effort and increasing efficiency. We analyze your workflows, identify automation opportunities, and implement AI solutions that enhance productivity and minimize errors.
          </p>
        </section>

        <section className="service-section">
          <h2>Digital Transformation</h2>
          <p>
            Embrace the digital revolution with our digital transformation services. We guide businesses in adopting new technologies and processes that enhance customer experience, operational agility, and overall competitiveness in the market.
          </p>
        </section>

        <section className="service-section">
          <h2>UX/UI Design</h2>
          <p>
            User experience is critical to the success of any AI solution. Our UX/UI design team focuses on creating intuitive and engaging interfaces that enhance user interaction with AI technologies, ensuring a seamless experience for your customers.
          </p>
        </section>

        <section className="service-section">
          <h2>AI Coaching & Optimization</h2>
          <p>
            We provide ongoing AI coaching and optimization services to help your team leverage AI technologies effectively. Our experts work alongside your staff, offering training, support, and performance analysis to ensure your AI systems deliver maximum value.
          </p>
        </section>

        <section className="service-section">
          <h2>AI Ethics and Governance</h2>
          <p>
            Our commitment to ethical AI practices ensures that your AI implementations adhere to best practices in transparency, fairness, and accountability. We assist in navigating the complexities of AI governance and compliance, fostering trust among stakeholders.
          </p>
        </section>

        <section className="service-section">
          <h2>Industry-Specific Solutions</h2>
          <p>
            CortexDynamics offers industry-specific AI solutions that address the unique challenges faced by various sectors, including finance, healthcare, retail, and manufacturing. Our deep domain knowledge allows us to deliver targeted solutions that drive real impact.
          </p>
        </section>

        <section className="service-section">
          <h2>Contact Us for a Consultation</h2>
          <p>
            Ready to take your business to the next level with AI? Contact us today to schedule a consultation and discover how CortexDynamics can help you harness the power of artificial intelligence to achieve your business goals.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
