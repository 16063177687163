import React from 'react';
import '../css/CEOsVision.css'; // Import your CSS file

const CEOsVision = () => {
    return (
        <div className="ceos-vision-section">
        <div className="ceo-picture">
            <img src={require('../images/kavin.jpeg')} alt="Kavin Parikh" />
        </div>
        <div className="vision-text">
            <h2>CEO's Vision</h2>
            <p> Our Founder and CEO, Kavin Parikh, envisions a pioneering company in the tech consulting sector, distinguished by innovative solutions and a strong commitment to customer satisfaction. Under his leadership, we strive to deliver customized strategies that drive business growth and empower our clients to succeed. 
            </p> 
            <p> Kavin’s vision is firmly anchored in the belief that technology serves as a transformative force for businesses. By leveraging advanced technologies and industry best practices, he aims to provide solutions that not only tackle present challenges but also anticipate future demands. 
            </p> 
            <p> His leadership approach embodies a relentless pursuit of excellence and a passion for continuous improvement. Kavin cultivates a culture of collaboration and innovation within our organization, inspiring team members to think creatively and explore the frontiers of what is achievable. 
            </p> 
            <p> Kavin’s commitment to client satisfaction is unwavering. He understands that building enduring relationships with clients is crucial for mutual success. By gaining insights into their unique needs and delivering tailored solutions, he ensures that our clients reach their strategic objectives and maintain a competitive edge. 
            </p> 
            <p> Looking ahead, Kavin is dedicated to expanding our global footprint and enhancing our service offerings. He is focused on driving sustainable growth and creating value for all stakeholders while upholding the highest standards of integrity and professionalism. 
            </p>
        </div>
    </div>
    );
};

export default CEOsVision;
