// src/pages/DataAnalytics.js
import React from 'react';
import Header from '../components/Header'; // Ensure you import the Header component correctly
import Footer from '../components/Footer'; // Ensure you import the Footer component correctly
import '../css/DataAnalytics.css'; // Import the corresponding CSS file

const DataAnalytics = () => {
    return (
        <>
            <Header />
            <div className="data-analytics-container">
                <h1>Data Analytics Solutions</h1>
                <p>
                    In today's data-driven world, harnessing the power of data analytics is essential 
                    for making informed business decisions. Our data analytics solutions are designed 
                    to help you turn raw data into actionable insights that drive growth and efficiency.
                </p>

                <h2>Our Approach</h2>
                <p>
                    We employ a comprehensive approach to data analytics, which includes the following 
                    key steps:
                </p>
                <ol>
                    <li>
                        <strong>Data Collection:</strong> We gather data from various sources, ensuring 
                        a comprehensive dataset for analysis.
                    </li>
                    <li>
                        <strong>Data Cleaning:</strong> Our team cleans and preprocesses the data to 
                        eliminate inconsistencies and inaccuracies.
                    </li>
                    <li>
                        <strong>Data Analysis:</strong> We apply advanced analytical techniques to 
                        uncover trends, patterns, and insights within the data.
                    </li>
                    <li>
                        <strong>Visualization:</strong> Data visualizations help you easily understand 
                        complex information and make informed decisions.
                    </li>
                    <li>
                        <strong>Actionable Insights:</strong> We provide recommendations based on the 
                        analysis, enabling you to take strategic actions.
                    </li>
                </ol>

                <h2>Why Choose Our Data Analytics Solutions?</h2>
                <p>
                    Our data analytics solutions empower your organization to leverage data effectively. 
                    With our expertise, you can expect improved decision-making, enhanced operational 
                    efficiency, and a competitive edge in your industry.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default DataAnalytics;
