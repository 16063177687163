// frontend/src/pages/AssessmentPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header'; // Adjust the path based on your structure
import Footer from '../components/Footer'; // Adjust the path based on your structure
import '../css/AssessmentPage.css'; // Import CSS for styling
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO


const AssessmentPage = () => {
  return (
    <div className="assessment-page">
      <Helmet>
        <title>AI Maturity Assessment - Cortex Dynamics</title>
        <meta
          name="description"
          content="Take the AI Maturity Assessment to evaluate your organization's AI capabilities. Discover areas for improvement and get tailored recommendations."
        />
        <meta
          name="keywords"
          content="AI Maturity Assessment, AI capabilities, technology assessment, business improvement, Cortex Dynamics"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.cortexdynamics.in/assessment" />
      </Helmet>

      <Header />  {/* Include the header component */}

      <main>
        <h1>AI Maturity Assessment</h1>
        <img src="/ai-assessments.jpg" alt="AI Assessment" className="hero-image" />
        
        <p className="intro-text">
          Welcome to our <strong>AI Maturity Assessment</strong>! This tool is designed to provide valuable 
          insights into your organization’s AI capabilities and identify potential areas for improvement. 
          Completing this assessment will empower your business to leverage AI technologies more effectively.
        </p>

        <section className="info-section">
          <h2>Why Assess Your AI Maturity?</h2>
          <p>
            In today's competitive landscape, harnessing the power of AI is essential for business success. 
            Assessing your AI maturity will help you:
          </p>
          <ul>
            <li><strong>Evaluate Current Capabilities:</strong> Gain a clear understanding of your existing AI usage.</li>
            <li><strong>Identify Gaps:</strong> Discover shortcomings in your AI strategy and execution.</li>
            <li><strong>Receive Tailored Recommendations:</strong> Obtain specific steps to enhance your AI maturity.</li>
            <li><strong>Benchmark Performance:</strong> Compare your AI capabilities against industry standards.</li>
          </ul>
        </section>

        <section className="info-section">
          <h2>What to Expect</h2>
          <p>
            The assessment consists of a series of straightforward questions covering various 
            aspects of AI implementation in your organization. Here's what you can expect:
          </p>
          <ul>
            <li>Quick and easy completion in just a few minutes.</li>
            <li>Immediate feedback based on your responses.</li>
            <li>A comprehensive report highlighting your strengths and areas for development.</li>
            <li>Actionable insights to help elevate your AI strategy.</li>
          </ul>
        </section>

        <section className="info-section">
            <h2>How to Get Started</h2>
            <p>
            Ready to uncover the potential of AI within your business? Click the button below to 
            start your assessment and take the first step toward enhancing your AI capabilities.
            </p>
            <Link to="/assessment-form" className="cta-button">
                    Start Your Assessment
                </Link>
        </section>
      </main>

      <Footer />  {/* Include the footer component */}
    </div>
  );
};

export default AssessmentPage;
