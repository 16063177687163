import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import '../css/AssessmentForm.css'; // Import the CSS
import Header from '../components/Header';
import Footer from '../components/Footer';

const AssessmentForm = () => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        currentAIUsage: '',
        businessChallenge: '',
        goals: '',
        additionalComments: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '', // Clear the error for the current field on change
        });
    };

    const validate = () => {
        const newErrors = {};
        if (step === 0 && !formData.currentAIUsage) {
            newErrors.currentAIUsage = "Current AI usage is required.";
        }
        if (step === 1 && !formData.businessChallenge) {
            newErrors.businessChallenge = "Business challenge is required.";
        }
        if (step === 2 && !formData.goals) {
            newErrors.goals = "Goals are required.";
        }
        return newErrors;
    };

    const handleNext = () => {
        const newErrors = validate();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        const newErrors = validate(); // Form validation
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) { // Only proceed if no validation errors
            setLoading(true); // Set loading state to true to show loading spinner

            try {
                const response = await fetch('http://localhost:5000/submit-form', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData), // Send the form data as JSON
                });

                if (!response.ok) {
                    throw new Error('Failed to submit form');
                }

                const result = await response.json();
                console.log(result); // Log the response from the backend

                alert("Form submitted successfully!");

                // Reset form data after successful submission
                setFormData({
                    currentAIUsage: '',
                    businessChallenge: '',
                    goals: '',
                    additionalComments: '',
                });

                // Reset to the first step if desired
                setStep(0);
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('There was an error submitting the form. Please try again.');
            } finally {
                setLoading(false); // Set loading state to false after the request completes
            }
        }
    };

    return (
        <div className="assessment-form">

            <Header />

            <h4>AI Assessment Form</h4>
            <div className="stepper">
                <div className="step-label">Step {step + 1} of 4</div>
                <div className="progress-bar" style={{ width: `${((step + 1) / 4) * 100}%` }} />
            </div>

            <form onSubmit={handleSubmit}>
                {step === 0 && (
                    <div className="text-field">
                        <label htmlFor="currentAIUsage">
                            Current AI Usage
                            <span data-tip="What AI tools or technologies are you currently using?" data-for="currentAIUsageTooltip">
                                ?
                            </span>
                        </label>
                        <input
                            type="text"
                            id="currentAIUsage"
                            name="currentAIUsage"
                            value={formData.currentAIUsage}
                            onChange={handleChange}
                            className={`text-field ${errors.currentAIUsage ? 'invalid' : ''}`}
                        />
                        {errors.currentAIUsage && <div className="error-message">{errors.currentAIUsage}</div>}
                        <ReactTooltip id="currentAIUsageTooltip" place="top" effect="solid" />
                    </div>
                )}
                {step === 1 && (
                    <div className="text-field">
                        <label htmlFor="businessChallenge">
                            Business Challenge
                            <span data-tip="Describe the main challenge your business is facing." data-for="businessChallengeTooltip">
                                ?
                            </span>
                        </label>
                        <textarea
                            id="businessChallenge"
                            name="businessChallenge"
                            value={formData.businessChallenge}
                            onChange={handleChange}
                            className={`text-field ${errors.businessChallenge ? 'invalid' : ''}`}
                        />
                        {errors.businessChallenge && <div className="error-message">{errors.businessChallenge}</div>}
                        <ReactTooltip id="businessChallengeTooltip" place="top" effect="solid" />
                    </div>
                )}
                {step === 2 && (
                    <div className="text-field">
                        <label htmlFor="goals">
                            Goals
                            <span data-tip="What are your objectives for implementing AI?" data-for="goalsTooltip">
                                ?
                            </span>
                        </label>
                        <input
                            type="text"
                            id="goals"
                            name="goals"
                            value={formData.goals}
                            onChange={handleChange}
                            className={`text-field ${errors.goals ? 'invalid' : ''}`}
                        />
                        {errors.goals && <div className="error-message">{errors.goals}</div>}
                        <ReactTooltip id="goalsTooltip" place="top" effect="solid" />
                    </div>
                )}
                {step === 3 && (
                    <div className="text-field">
                        <label htmlFor="additionalComments">
                            Additional Comments
                            <span data-tip="Any other information you'd like to share?" data-for="additionalCommentsTooltip">
                                ?
                            </span>
                        </label>
                        <textarea
                            id="additionalComments"
                            name="additionalComments"
                            value={formData.additionalComments}
                            onChange={handleChange}
                            className="text-field"
                        />
                        <ReactTooltip id="additionalCommentsTooltip" place="top" effect="solid" />
                    </div>
                )}

                <div className="button-container">
                    {step > 0 && <button type="button" onClick={handleBack}>Back</button>}
                    {step < 3 ? (
                        <button type="button" onClick={handleNext}>Next</button>
                    ) : (
                        loading ? (
                            <div className="loading-indicator">Submitting...</div>
                        ) : (
                            <button type="button" onClick={handleSubmit}>Submit</button>
                        )
                    )}
                </div>
            </form>

            <Footer />

        </div>
    );
};

export default AssessmentForm;
