import React, { useState } from 'react';
import '../css/PricingPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PricingPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const pricingOptions = [
    {
      title: 'Consulting Services',
      content: (
        <>
          <p>We offer tiered consulting packages to match your business requirements:</p>
          <ul>
            <li><strong>Basic Package:</strong> Essential consulting services for startups. This package includes a comprehensive analysis of your current AI capabilities and a detailed report on how to improve.</li>
            <li><strong>Advanced Package:</strong> Comprehensive strategies and ongoing support for growing businesses. This package includes regular follow-up consultations, strategy optimization, and performance tracking.</li>
            <li><strong>Premium Package:</strong> All-inclusive services with dedicated support and customized strategies tailored specifically for your industry. You will have direct access to our experts and priority support.</li>
            <li><strong>Hourly Consultations:</strong> Flexible pricing for ad-hoc support tailored to your schedule. Ideal for businesses needing specific advice without committing to a full package.</li>
            <li><strong>Project-Based Consulting:</strong> Fixed or milestone-based rates tailored to specific project requirements. We work with you to establish clear deliverables and timelines, ensuring transparency and accountability.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Project-Based Fees',
      content: (
        <>
          <p>Optimize your investment with our project pricing models:</p>
          <ul>
            <li><strong>Fixed Project Fees:</strong> Clearly defined costs for specific deliverables and timelines. This model is perfect for projects with well-defined scopes, ensuring budget predictability.</li>
            <li><strong>Time and Materials:</strong> Pay based on actual hours worked and materials utilized, ensuring transparency and flexibility in project execution.</li>
            <li><strong>Value-Based Pricing:</strong> Charges reflect the measurable impact our solutions deliver to your business. This approach aligns our success with yours, ensuring you get maximum value from our services.</li>
          </ul>
          <p>Our team is committed to delivering high-quality results on time and within budget, providing you with the assurance that your projects are in capable hands.</p>
        </>
      ),
    },
    {
      title: 'Subscription Models',
      content: (
        <>
          <p>Enjoy flexible access to our tools and platforms through our subscription plans:</p>
          <ul>
            <li><strong>Monthly Plans:</strong> Flexible, pay-as-you-go access to essential AI tools and resources, perfect for businesses that need short-term solutions.</li>
            <li><strong>Annual Plans:</strong> Cost-effective yearly subscription with additional benefits, including premium support and access to exclusive resources and updates.</li>
            <li><strong>Feature-Based Pricing:</strong> Tailor your subscription based on the features you need. Choose from various functionalities, including advanced analytics, predictive modeling, and real-time data processing.</li>
            <li><strong>User-Based Pricing:</strong> Pricing adjusts according to the number of users or volume of usage. Ideal for growing teams that need scalable solutions without compromising on performance.</li>
          </ul>
          <p>Our subscription models ensure that you have the tools and support necessary to drive your AI initiatives forward effectively.</p>
        </>
      ),
    },
    {
      title: 'Training and Workshops',
      content: (
        <>
          <p>Elevate your team's skills with our specialized training programs:</p>
          <ul>
            <li><strong>Per Participant Fees:</strong> Cost-effective pricing based on the number of attendees. Our workshops are designed to provide hands-on experience and practical insights.</li>
            <li><strong>Package Deals:</strong> Attractive discounts for booking multiple sessions or group training. This is a great option for teams looking to develop their skills in a cohesive manner.</li>
            <li><strong>Premium Workshops:</strong> In-depth sessions covering advanced topics, tailored for experienced professionals. These workshops delve into specialized areas of AI, including machine learning algorithms, data visualization, and strategy formulation.</li>
          </ul>
          <p>Our trainers are industry experts with years of experience, ensuring that your team receives the highest quality education tailored to your business needs.</p>
        </>
      ),
    },
  ];

  return (
    <div className="pricing-page">
      <Header />
      <div className="content">
        <h1>Pricing Plans Tailored for Your Success</h1>
        <p className="intro-text">
          Our pricing structure is designed to cater to businesses of all sizes and needs. Whether you’re looking for consulting services, project-based revenue models, subscription plans, or training workshops, we have options that deliver value and flexibility.
        </p>
        <div className="accordion">
          {pricingOptions.map((option, index) => (
            <div key={index} className="accordion-item">
              <h2 className="accordion-title" onClick={() => toggleAccordion(index)}>
                {option.title}
              </h2>
              <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
                {option.content}
                {/* <a href="#" className="signup-button">Choose This Plan</a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PricingPage;
