// src/services/CustomSolutions.js
import React from 'react';
import Header from '../components/Header'; // Ensure you import the Header component correctly
import Footer from '../components/Footer'; // Ensure you import the Footer component correctly
import '../css/CustomSolutions.css'; // Import the corresponding CSS file

const CustomSolutions = () => {
    return (
        <>
            <Header />
            <div className="custom-solutions-container">
                <h1>Custom and Tailored Solutions</h1>
                <p>
                    At our company, we understand that every business is unique. That's why we offer 
                    custom and tailored AI solutions designed specifically for your organization's 
                    needs. Our approach involves a thorough assessment of your existing processes 
                    and the challenges you face to create solutions that drive efficiency and growth.
                </p>

                <h2>Our Process</h2>
                <p>
                    Our process for developing custom solutions includes the following steps:
                </p>
                <ol>
                    <li>
                        <strong>Consultation:</strong> We start with a detailed consultation to understand 
                        your business goals and challenges.
                    </li>
                    <li>
                        <strong>Needs Assessment:</strong> We conduct an assessment to identify specific 
                        areas where AI can make a meaningful impact.
                    </li>
                    <li>
                        <strong>Solution Design:</strong> Our team designs a tailored solution that 
                        integrates seamlessly with your existing systems.
                    </li>
                    <li>
                        <strong>Implementation:</strong> We assist you in implementing the solution, 
                        ensuring it meets your operational needs.
                    </li>
                    <li>
                        <strong>Ongoing Support:</strong> After implementation, we provide ongoing support 
                        to ensure the solution continues to meet your business needs.
                    </li>
                </ol>

                <h2>Why Choose Our Custom Solutions?</h2>
                <p>
                    Choosing our custom solutions means you're opting for a partnership that values 
                    your unique challenges and goals. We leverage our expertise to deliver solutions that 
                    not only solve immediate issues but also position you for long-term success.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default CustomSolutions;
